import React from 'react';
import MainContainer from '../components/main-container';
import { Link } from 'gatsby';

export default function NotFoundPage() {
    return (
        <MainContainer indexPage>
            <video
                src='/404.mp4'
                autoPlay
                className='max-w-full mb-6 rounded shadow'
                loop
            />
            <Link to='/' className='text-lg text-blue-500 font-weight-600'>
                👉 Back to Main Page
            </Link>
        </MainContainer>
    );
}
